.player-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.player-wrapper video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}
