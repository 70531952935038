.mobile-navigation-menu-enter {

  
}
.mobile-navigation-menu-enter-active {

}
.mobile-navigation-menu-exit {
  /* opacity: 1; */
  transform: translate(0px, 0px);
}
.mobile-navigation-menu-exit-active {
  /* opacity: 0; */
  transform: translate(100%, 0px);
  transition: all ease-out 1000ms;
}
